import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter as Router } from '@react-router';
import { loadableReady } from '@loadable/component';
import { useSSR } from 'react-i18next';
import { ErrorBoundary } from './ErrorBoundary';
import window from '@shared/core/globals';
import App from './App';
import '@shared/core/i18n/i18n';
import { FullPageLoaderProvider } from '@shared/components/JoyLogoLoader/FullPageLoaderProvider';

const BaseApp: React.FC = props => {
  useSSR(window.__INITIAL_I18N_STORE__, window.__INITIAL_I18N_LANG__);
  return (
    <ErrorBoundary>
      <FullPageLoaderProvider>
        <Router>
          <App {...props} defaultScreen={window.__INITIAL_DEVICE_SCREEN__} />
        </Router>
      </FullPageLoaderProvider>
    </ErrorBoundary>
  );
};

// Wait for all scripts to load asynchronously
loadableReady(() => {
  const root = document.getElementById('root');

  hydrate(<BaseApp />, root);
});

if (process.env.NODE_ENV === 'development') {
  module.hot?.accept();
}
